import request from '@/utils/request2'
import Dao from '@/utils/dao'

function returnHeader() {
  return {
    'x-5a-temp-token': Dao.get('claimToken')
  }
}

export function getDetail(query) {
  return request({
    url: '/pro/claim/form/single/detail',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForDraft(query) {
  return request({
    url: '/pro/claim/form/save',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}

export function saveForSubmit(query) {
  return request({
    url: '/pro/claim/form/submit',
    method: 'post',
    headers: returnHeader(),
    data: query
  })
}
